<template>
  <component :is="productsData === undefined ? 'div' : 'div'">
    <b-modal
      id="document-upload"
      ref="documentUpload"
      hide-footer
      centered
      size="sm"
      title="Document uploaden"
      :no-close-on-backdrop="true"
    >
      <b-card-text>
        <template #title>
          <span>Document uploaden</span>
        </template>

        <b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
          >
            <b-form
              @submit.prevent="handleSubmit(createProductFile)"
            >
              <b-row>
                <b-col
                  cols="8"
                >
                  <validation-provider
                    #default="validationContext"
                    name="fileDescription"
                    rules="required"
                  >
                    <b-form-group
                      label="Naam"
                      label-for="fileDescription"
                    >
                      <b-form-input
                        id="fileDescription"
                        v-model="newFile.description"
                        :state="getValidationState(validationContext)"
                        readonly="readonly"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="4"
                  class="d-flex align-items-center"
                >
                  <b-button
                    v-b-toggle.report-list
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    style="margin-top:10px"
                    size="sm"
                    variant="outline-primary"
                    @click="$refs.refInputEl1.click()"
                  >
                    <b-spinner
                      v-if="showUploadLoader"
                      small
                      class="mr-1"
                    />
                    <input
                      id="refInputEl1"
                      ref="refInputEl1"
                      type="file"
                      class="d-none"
                      @input="uploadDocument()"
                    >
                    <span class="d-none d-sm-inline">Uploaden</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
                <b-col
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="fileType"
                    rules="required"
                  >
                    <b-form-group
                      label="Type"
                      label-for="fileType"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="newFile.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="fileTypeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="fileType"
                        name="fileType"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12 text-right mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="showUploadLoader"
                  >
                    <b-spinner
                      v-if="showCreateProductLoader"
                      small
                      class="mr-1"
                    />
                    <span>Opslaan</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-text>
    </b-modal>
    <template v-if="productsData">
      <b-card
        title="Informatie"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormProduct1Observer"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit1)"
          >
            <b-card-body>
              <b-row>
                <!-- Field: ID -->
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group
                    label="ID"
                    label-for="id"
                  >
                    <b-form-input
                      id="id"
                      v-model="productsData.id"
                      readonly="readonly"
                    />
                  </b-form-group>
                </b-col>

                <!-- CreatedDate -->
                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group
                    label="Aangemaakt op"
                    label-for="createTimestamp"
                  >
                    <b-form-input
                      id="createTimestamp"
                      v-model="productsData.createTimestamp"
                      readonly="readonly"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="type"
                    rules="required"
                    vid="product_type"
                  >
                    <b-form-group
                      label="Type"
                      label-for="type"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="productsData.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="typeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="user-status"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="user-status"
                    rules="required"
                  >
                    <b-form-group
                      label="Status"
                      label-for="user-status"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="productsData.active"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="user-status"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="name"
                    rules="required"
                  >
                    <b-form-group
                      label="Naam"
                      label-for="name"
                    >
                      <b-form-input
                        id="name"
                        v-model="productsData.name"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="supplierID"
                    rules="required"
                  >
                    <b-form-group
                      label="Label"
                      label-for="supplierID"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="productsData.supplierID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="suppliersOptions"
                        :reduce="val => val.id"
                        :clearable="false"
                        input-id="user-status"
                        label="name"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="description"
                    rules="required"
                  >
                    <b-form-group
                      label="Beschrijving"
                      label-for="description"
                    >
                      <b-form-textarea
                        id="description"
                        v-model="productsData.description"
                        rows="3"
                        no-resize
                        maxlength="100"
                        trim
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="USP's"
                    label-for="usp"
                  >
                    <quill-editor
                      id="usp"
                      v-model="productsData.uspText"
                      :options="snowOption"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <b-button
                v-if="$can('update', 'product')"
                class="mt-1"
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Opslaan
              </b-button>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-card>
      <b-card-actions
        :action-collapse="true"
        :action-refresh="false"
        :action-close="false"
        :collapsed="true"
        title="Eigenschappen"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormProduct2Observer"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit2)"
          >
            <b-card-body>
              <b-row>
                <b-col
                  v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="overideEcedoElectricityPVEAN"
                    rules=""
                  >
                    <b-form-group
                      label="Elektriciteit PV"
                      label-for="overideEcedoElectricityPVEAN"
                    >
                      <b-form-input
                        id="overideEcedoElectricityPVEAN"
                        v-model="productsData.overideEcedoElectricityPVEAN"
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="productsData.type === 'DUAL' || productsData.type === 'GAS'"
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="overideEcedoGasPVEAN"
                    rules=""
                  >
                    <b-form-group
                      label="Gas PV"
                      label-for="overideEcedoGasPVEAN "
                    >
                      <b-form-input
                        id="overideEcedoGasPVEAN"
                        v-model="productsData.overideEcedoGasPVEAN "
                        autofocus
                        :state="getValidationState(validationContext)"
                        trim
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="ecedoElectricityPropositionSuffix"
                    rules="required_if:product_type,DUAL|required_if:product_type,ELECTRICITY"
                  >
                    <b-form-group
                      label="Elektriciteit Reference"
                      label-for="ecedoElectricityPropositionSuffix"
                    >
                      <b-form-input
                        id="ecedoElectricityPropositionSuffix"
                        v-model="productsData.ecedoElectricityPropositionSuffix"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="productsData.type === 'DUAL' || productsData.type === 'GAS'"
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="ecedoGasPropositionSuffix"
                    rules="required_if:product_type,DUAL|required_if:product_type,GAS"
                  >
                    <b-form-group
                      label="Gas Reference"
                      label-for="ecedoGasPropositionSuffix"
                    >
                      <b-form-input
                        id="ecedoGasPropositionSuffix"
                        v-model="productsData.ecedoGasPropositionSuffix"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="ecedoElectricityMeeliftPropositionReference"
                    rules="required_if:product_type,DUAL|required_if:product_type,ELECTRICITY"
                  >
                    <b-form-group
                      label="Elektriciteit Meelift Reference"
                      label-for="ecedoElectricityMeeliftPropositionReference"
                    >
                      <b-form-input
                        id="ecedoElectricityMeeliftPropositionReference"
                        v-model="productsData.ecedoElectricityMeeliftPropositionReference"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="productsData.type === 'DUAL' || productsData.type === 'GAS'"
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="ecedoGasMeeliftPropositionReference"
                    rules="required_if:product_type,DUAL|required_if:product_type,GAS"
                  >
                    <b-form-group
                      label="Gas Meelift Reference"
                      label-for="ecedoGasMeeliftPropositionReference"
                    >
                      <b-form-input
                        id="ecedoGasMeeliftPropositionReference"
                        v-model="productsData.ecedoGasMeeliftPropositionReference"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="2"
                >
                  <validation-provider
                    #default="validationContext"
                    name="contractDuration"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Looptijd (in maanden)"
                      label-for="contractDuration"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-input
                        id="contractDuration"
                        v-model="productsData.contractDuration"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="isBusiness"
                    rules="required"
                  >
                    <label>Particulier / Zakelijk</label>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="productsData.isBusiness"
                        name="isBusiness"
                        :value="0"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                        @input="resetBagAndSoho"
                      >
                        Particulier
                      </b-form-radio>
                      <b-form-radio
                        v-model="productsData.isBusiness"
                        name="isBusiness"
                        :value="1"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Zakelijk
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="productsData.isBusiness === 2"
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="bagLimitationType"
                    rules="required"
                  >
                    <b-form-group
                      label="BAG Beperking"
                      label-for="bagLimitationType"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="productsData.bagLimitationType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="bagOptions"
                        :reduce="val => val.value"
                        :clearable="true"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="productsData.isBusiness === 2"
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="isSohoProposition"
                    rules="required_if:isBusiness,2"
                  >
                    <label>SOHO</label>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="productsData.isSohoProposition"
                        name="isSohoProposition"
                        :value="0"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Nee
                      </b-form-radio>
                      <b-form-radio
                        v-model="productsData.isSohoProposition"
                        name="isSohoProposition"
                        :value="1"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Ja
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="isGV"
                    rules="required"
                  >
                    <label>Kleinverbruik / Grootverbruik</label>
                    <div class="demo-inline-spacing">
                      <b-form-radio
                        v-model="productsData.isGV"
                        name="isGV"
                        :value="0"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Kleinverbruik
                      </b-form-radio>
                      <b-form-radio
                        v-model="productsData.isGV"
                        name="isGV"
                        :value="1"
                        class="mt-0"
                        :state="getValidationState(validationContext)"
                      >
                        Grootverbruik
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="orderExpiryDays"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Offerte verloopt na (+dagen)"
                      label-for="orderExpiryDays"
                    >
                      <b-form-input
                        id="defaultSwitchWindowDays"
                        v-model="productsData.orderExpiryDays"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <b-button
                v-if="$can('update', 'product')"
                class="mt-1"
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Opslaan
              </b-button>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-card-actions>
      <b-card-actions
        :action-collapse="true"
        :action-refresh="false"
        :action-close="false"
        :collapsed="true"
        title="Cashback & Kortingen"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormProduct3Observer"
        >
          <b-form
            @submit.prevent="handleSubmit(updateBillingItems)"
          >
            <b-card-body>
              <b-row
                v-for="(item, index) in productBillingItems"
                :key="item.id"
              >
                <!--                <b-col-->
                <!--                  cols="12"-->
                <!--                  md="2"-->
                <!--                >-->
                <!--                  <validation-provider-->
                <!--                    #default="validationContext"-->
                <!--                    :name="`idBillingItem${index}`"-->
                <!--                    rules=""-->
                <!--                  >-->
                <!--                    <b-form-group-->
                <!--                      label="ID"-->
                <!--                      :label-for="`idBillingItem${index}`"-->
                <!--                    >-->
                <!--                      <b-form-input-->
                <!--                        :id="`idBillingItem${index}`"-->
                <!--                        v-model="item.id"-->
                <!--                        :state="getValidationState(validationContext)"-->
                <!--                        disabled="disabled"-->
                <!--                      />-->
                <!--                      <b-form-invalid-feedback :state="getValidationState(validationContext)">-->
                <!--                        {{ validationContext.errors[0] }}-->
                <!--                      </b-form-invalid-feedback>-->
                <!--                    </b-form-group>-->
                <!--                  </validation-provider>-->
                <!--                </b-col>-->
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="`ecedoUUIDBillingItem${index}`"
                    rules="required"
                  >
                    <b-form-group
                      label="ERP ID"
                      :label-for="`ecedoUUIDBillingItem${index}`"
                    >
                      <b-form-input
                        :id="`ecedoUUIDBillingItem${index}`"
                        v-model="item.ecedoUUID"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="`nameBillingItem${index}`"
                    rules="required"
                  >
                    <b-form-group
                      label="Naam"
                      :label-for="`nameBillingItem${index}`"
                    >
                      <b-form-input
                        :id="`nameBillingItem${index}`"
                        v-model="item.name"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="`applyAfterDaysBillingItem${index}`"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Toepassen na (+dagen)"
                      :label-for="`applyAfterDaysBillingItem${index}`"
                    >
                      <b-form-input
                        :id="`applyAfterDaysBillingItem${index}`"
                        v-model="item.applyAfterDays"
                        type="number"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="6"
                  md="2"
                  class="d-flex align-items-center"
                >
                  <b-button
                    v-b-toggle.report-list
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    style="margin-top:10px"
                    size="sm"
                    variant="outline-primary"
                    @click="removeProductBillingItem(index)"
                  >
                    <span class="d-none d-sm-inline">Verwijderen</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-footer>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary mt-1 mr-2"
                type="button"
                @click="addProductBillingItem"
              >
                <span>Toevoegen</span>
              </b-button>
              <b-button
                v-if="$can('update', 'product')"
                class="mt-1"
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Opslaan
              </b-button>
            </b-card-footer>
          </b-form></validation-observer></b-card-actions>
      <b-card-actions
        :action-collapse="true"
        :action-refresh="false"
        :action-close="false"
        :collapsed="true"
        title="Pdf"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormProduct4Observer"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit4)"
          >
            <b-card-body>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="pdfTemplateID"
                    rules=""
                  >
                    <b-form-group
                      label="Pdf template"
                      label-for="pdfTemplateID"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="pdfTemplateID"
                        v-model="productsData.pdfTemplateID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="pdfTempalates"
                        :reduce="val => val.value"
                        :clearable="true"
                        :name="`type{index}`"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="electricityType"
                    rules="required"
                  >
                    <b-form-group
                      label="Elektriciteit type"
                      label-for="electricityType"
                    >
                      <b-form-input
                        id="reflectionPeriodText"
                        v-model="productsData.electricityType"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="productsData.type === 'DUAL' || productsData.type === 'GAS'"
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="gasType"
                    rules="required"
                  >
                    <b-form-group
                      label="Gas type"
                      label-for="gasType"
                    >
                      <b-form-input
                        id="reflectionPeriodText"
                        v-model="productsData.gasType"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="reflectionPeriodText"
                    rules="required"
                  >
                    <b-form-group
                      label="Bedenktijd"
                      label-for="reflectionPeriodText"
                    >
                      <b-form-input
                        id="reflectionPeriodText"
                        v-model="productsData.reflectionPeriodText"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="fineCompensationText"
                    rules=""
                  >
                    <b-form-group
                      label="Compensatie opzegvergoeding"
                      label-for="fineCompensationText"
                    >
                      <b-form-input
                        id="fineCompensationText"
                        v-model="productsData.fineCompensationText"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="loyaltyBonusText"
                    rules=""
                  >
                    <b-form-group
                      label="Loyaliteitsbonus"
                      label-for="loyaltyBonusText"
                    >
                      <b-form-input
                        id="loyaltyBonusText"
                        v-model="productsData.loyaltyBonusText"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="cashBackText"
                    rules=""
                  >
                    <b-form-group
                      label="Cashback"
                      label-for="cashBackText"
                    >
                      <b-form-input
                        id="cashBackText"
                        v-model="productsData.cashBackText"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Voorwaarden"
                    label-for="policyText"
                  >
                    <quill-editor
                      id="policyText"
                      v-model="productsData.policyText"
                      :options="snowOption"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Extra informatie"
                    label-for="usp"
                  >
                    <quill-editor
                      id="usp"
                      v-model="productsData.extraInformationText"
                      :options="snowOption"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <b-button
                v-if="$can('update', 'product')"
                class="mt-1"
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Opslaan
              </b-button>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-card-actions>
      <b-card-actions
        :action-collapse="true"
        :action-refresh="false"
        :action-close="false"
        :collapsed="true"
        title="Switch venster"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormProduct5Observer"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit5)"
          >
            <b-card-body>
              <b-row>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="switchWindowMinDays"
                    rules="required|integer|min_value:0"
                    vid="switch_window_min"
                  >
                    <b-form-group
                      label="Minimum (+dagen)"
                      label-for="switchWindowMinDays"
                    >
                      <b-form-input
                        id="switchWindowMinDays"
                        v-model="productsData.switchWindowMinDays"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="switchWindowsMaxDays"
                    :rules="`required|integer|min_value:${productsData.switchWindowMinDays}`"
                  >
                    <b-form-group
                      label="Maximum (+dagen)"
                      label-for="switchWindowsMaxDays"
                    >
                      <b-form-input
                        id="switchWindowsMaxDays"
                        v-model="productsData.switchWindowsMaxDays"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="defaultSwitchWindowDays"
                    :rules="`required|integer|between:${productsData.switchWindowMinDays},${productsData.switchWindowsMaxDays}`"
                  >
                    <b-form-group
                      label="Standaard (+dagen)"
                      label-for="defaultSwitchWindowDays"
                    >
                      <b-form-input
                        id="defaultSwitchWindowDays"
                        v-model="productsData.defaultSwitchWindowDays"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-footer>
              <b-button
                v-if="$can('update', 'product')"
                class="mt-1"
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                Opslaan
              </b-button>
            </b-card-footer>
          </b-form>
        </validation-observer>
      </b-card-actions>
      <b-card-actions
        v-if="productsData.currentTariff"
        ref="refreshProduct"
        :action-collapse="true"
        :action-refresh="true"
        :action-close="false"
        :collapsed="true"
        title="Tarieven"
        @refresh="syncProductPrices"
      >
        <b-row>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Elektriciteit Reference"
              label-for="validFrom"
            >
              <b-form-input
                id="ecedoElectricityPropositionReference"
                v-model="productsData.lastTariff.ecedoElectricityPropositionReference"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'GAS'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Gas Reference"
              label-for="ecedoGasPropositionReference"
            >
              <b-form-input
                id="ecedoGasPropositionReference"
                v-model="productsData.lastTariff.ecedoGasPropositionReference"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Geldig vanaf"
              label-for="validFrom"
            >
              <b-form-input
                id="validFrom"
                v-model="productsData.lastTariff.validFrom"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Geldig tot en met"
              label-for="validTill"
            >
              <b-form-input
                id="validTill"
                v-model="productsData.lastTariff.validTill"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Laatst geupdate op"
              label-for="updateTimestamp"
            >
              <b-form-input
                id="updateTimestamp"
                v-model="productsData.lastTariff.updateTimestamp"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Elektriciteit enkeltarief"
              label-for="tariffSingle"
            >
              <b-form-input
                id="tariffSingle"
                v-model="productsData.lastTariff.tariffSingle"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Elektriciteit normaaltarief"
              label-for="tariffHigh"
            >
              <b-form-input
                id="tariffHigh"
                v-model="productsData.lastTariff.tariffHigh"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Elektriciteit daltarief"
              label-for="tariffLow"
            >
              <b-form-input
                id="tariffLow"
                v-model="productsData.lastTariff.tariffLow"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Vaste kosten elektriciteit"
              label-for="tariffFixedEnergy"
            >
              <b-form-input
                id="tariffFixedEnergy"
                v-model="productsData.lastTariff.tariffFixedEnergy"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Teruglevering elektriciteit enkeltarief"
              label-for="tariffReturnSingle"
            >
              <b-form-input
                id="tariffReturnSingle"
                v-model="productsData.lastTariff.tariffReturnSingle"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Teruglevering elektriciteit normaaltarief"
              label-for="tariffReturnHigh"
            >
              <b-form-input
                id="tariffReturnHigh"
                v-model="productsData.lastTariff.tariffReturnHigh"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'ELECTRICITY'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Teruglevering elektriciteit daltarief"
              label-for="tariffReturnLow"
            >
              <b-form-input
                id="tariffReturnLow"
                v-model="productsData.lastTariff.tariffReturnLow"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'GAS'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Leveringstarief gas (G1)"
              label-for="tariffGas1"
            >
              <b-form-input
                id="tariffGas1"
                v-model="productsData.lastTariff.tariffGas1"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'GAS'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Leveringstarief gas (G2)"
              label-for="tariffGas2"
            >
              <b-form-input
                id="tariffGas2"
                v-model="productsData.lastTariff.tariffGas2"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productsData.type === 'DUAL' || productsData.type === 'GAS'"
            cols="12"
            md="3"
          >
            <b-form-group
              label="Vaste kosten gas"
              label-for="tariffFixedGas"
            >
              <b-form-input
                id="tariffFixedGas"
                v-model="productsData.lastTariff.tariffFixedGas"
                :disabled="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-actions>
      <b-card-actions
        :action-collapse="true"
        :action-refresh="false"
        :action-close="false"
        :collapsed="true"
        title="Documenten"
      >
        <b-row
          v-for="(item, index) in files"
          :key="item.id"
        >
          <b-col
            cols="12"
            md="2"
          >
            <validation-provider
              #default="validationContext"
              :name="`id${index}`"
              rules=""
            >
              <b-form-group
                label="ID"
                :label-for="`id${index}`"
              >
                <b-form-input
                  :id="`id${index}`"
                  v-model="item.id"
                  :state="getValidationState(validationContext)"
                  readonly="readonly"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <validation-provider
              #default="validationContext"
              :name="`description${index}`"
              rules=""
            >
              <b-form-group
                label="Document"
                :label-for="`description${index}`"
              >
                <b-form-input
                  :id="`description${index}`"
                  v-model="item.description"
                  :state="getValidationState(validationContext)"
                  readonly="readonly"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              :name="`type${index}`"
              rules=""
            >
              <b-form-group
                label="Type"
                :label-for="`type${index}`"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  :id="`type${index}`"
                  v-model="item.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="fileTypeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :name="`type{index}`"
                  :disabled="true"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="6"
            md="2"
            class="d-flex align-items-center"
          >
            <b-button
              v-b-toggle.report-list
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              style="margin-top:10px"
              size="sm"
              variant="outline-primary"
              @click="removeFile(item.id)"
            >
              <span class="d-none d-sm-inline">Verwijderen</span>
              <feather-icon
                icon="EditIcon"
                class="d-inline d-sm-none"
              />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col
            cols="12"
            class="text-right mb-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="button"
              @click="$refs.documentUpload.show()"
            >
              <span>Document toevoegen</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-actions>
      <b-row>
        <b-col
          cols="12"
        >
          <b-card>
            <b-row>
              <b-col
                cols="6 text-left"
              >
                <b-button
                  variant="secondary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  type="button"
                  :to="{ name: 'apps-products-list' }"
                >
                  <span>Terug</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </template>

  </component>
</template>

<script>
import {
  BCard,
  BAlert,
  BLink,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BFormRadio, BSpinner, BCardText, VBToggle, BCardBody, BCardFooter,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import { formatDateTime } from '@core/utils/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import productsStoreModule from '@/views/pages/products/productsStoreModule'
import useProductsList from '@/views/pages/products/useProductsList'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  alphaNum,
  integer,
  required,
  decimal,
  requiredIf, min, minValue, test,
} from '@core/utils/validations/validations'
import { quillEditor } from 'vue-quill-editor'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import errorAndSuccesDefaultMessage from '@core/app-config/errorAndSuccesDefaultMessage'

export default {
  components: {
    BButton,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BRow,
    BCol,
    BFormRadio,
    BCardActions,
    BCardFooter,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormInvalidFeedback,
    quillEditor,
    BSpinner,
    BCardText,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      decimal,
      minValue,
      test,
      min,
      requiredIf,
      productsData: {
        isBusiness: 0,
        currentTariff: {
          createTimestamp: null,
          ecedoElectricityPropositionReference: null,
          ecedoGasPropositionReference: null,
          tariffFixedEnergy: null,
          tariffFixedGas: null,
          tariffGas1: null,
          tariffGas2: null,
          tariffHigh: null,
          tariffLow: null,
          tariffReturnHigh: null,
          tariffReturnLow: null,
          tariffReturnSingle: null,
          tariffSingle: null,
          updateTimestamp: null,
          validFrom: null,
          validTill: null,
          isGV: null,
          orderExpiryDays: null,
          overideEcedoElectricityPVEAN: null,
          overideEcedoGasPVEAN: null,
          pdfTemplateID: null,
        },
        lastTariff: {
          createTimestamp: null,
          ecedoElectricityPropositionReference: null,
          ecedoGasPropositionReference: null,
          tariffFixedEnergy: null,
          tariffFixedGas: null,
          tariffGas1: null,
          tariffGas2: null,
          tariffHigh: null,
          tariffLow: null,
          tariffReturnHigh: null,
          tariffReturnLow: null,
          tariffReturnSingle: null,
          tariffSingle: null,
          updateTimestamp: null,
          validFrom: null,
          validTill: null,
          isGV: null,
          orderExpiryDays: null,
          overideEcedoElectricityPVEAN: null,
          overideEcedoGasPVEAN: null,
        },
        electricityType: '',
        gasType: '',
        reflectionPeriodText: '',
        fineCompensationText: '',
        loyaltyBonusText: '',
        cashBackText: '',
        policyText: '',
        extraInformationText: '',
      },
      snowOption: {
        theme: 'snow',
      },
      durationOptions: [
        { label: '1 jaar', value: 12 },
        { label: '2 jaar', value: 24 },
        { label: '3 jaar', value: 36 },
        { label: '4 jaar', value: 48 },
        { label: '5 jaar', value: 60 },
      ],
      bagOptions: [
        { label: 'Toon altijd', value: 'NONE' },
        { label: 'Minimaal één zakelijke functie', value: 'NEEDBUSINESS' },
        { label: 'Geen woonfunctie aanwezig', value: 'BLOCKLIVINGFUNCTION' },
      ],
      showUploadLoader: false,
      showCreateProductLoader: false,
      showDeleteDocumentLoader: false,
      files: [],
      newFile: {
        fileID: null,
        description: null,
        type: null,
        productID: null,
      },
      suppliersOptions: [],
      productBillingItems: [],
    }
  },
  beforeMount() {
    this.fetchProduct()
    this.fetchSuppliers()
  },
  methods: {
    resetBagAndSoho() {
      this.productsData.bagLimitationType = 'NONE'
      this.productsData.isSohoProposition = 0
    },
    fetchProduct() {
      store.dispatch('apps-products-edit/fetchProduct', { id: router.currentRoute.params.id })
        .then(response => {
          response.data.createTimestamp = formatDateTime(response.data.createTimestamp)
          this.productsData = response.data
          this.fetchProductBillingItems(response.data.id)
          this.getFiles(response.data.id)
        })
        .catch(() => {
          this.productsData = undefined
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het ophalen van de productinformatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    fetchProductBillingItems(id) {
      store.dispatch('apps-products-edit/fetchProductBillingItems', { id })
        .then(response => {
          this.productBillingItems = response.data.items
        })
        .catch(() => {
          this.productBillingItems = []
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het ophalen van de product details',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async updateBillingItems() {
      await this.productBillingItems.forEach((item, index) => {
        const billingItem = item
        billingItem.productID = this.productsData.id
        if (billingItem.id) {
          store.dispatch('apps-products-edit/updateProductBillingItem', billingItem)
            .then(() => {
              if (index === this.productBillingItems.length - 1) {
                this.$swal({
                  icon: 'success',
                  title: 'Cashback & Kortingen updaten!',
                  text: `${errorAndSuccesDefaultMessage.updateDataSucces}`,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Variable',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Er is een fout opgetreden bij het update. Probeer het later nog een keer.',
                },
              })
            })
        } else {
          store.dispatch('apps-products-edit/createProductBillingItem', billingItem)
            .then(() => {
              if (index === this.productBillingItems.length - 1) {
                this.$swal({
                  icon: 'success',
                  title: 'Cashback & Kortingen updaten!',
                  text: `${errorAndSuccesDefaultMessage.updateDataSucces}`,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Variable',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: 'Er is een fout opgetreden bij het aanmaken. Probeer het later nog een keer.',
                },
              })
            })
        }
      })
    },
    addProductBillingItem() {
      this.productBillingItems.push(
        {
          id: null,
          name: '',
          productID: this.productsData.id,
          ecedoUUID: '',
          applyAfterDays: null,
        },
      )
    },
    removeProductBillingItem(index) {
      if (this.productBillingItems[index].id) {
        store.dispatch('apps-products-edit/removeProductBillingItem', {
          productID: this.productBillingItems[index].productID,
          id: this.productBillingItems[index].id,
        })
          .then(() => {
            this.productBillingItems.splice(index, 1)
            this.$swal({
              icon: 'success',
              title: 'Verwijderem',
              text: `${errorAndSuccesDefaultMessage.removeDataSucces}`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            this.productBillingItems = []
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Error bij het verwijderen!!!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.productBillingItems.splice(index, 1)
      }
    },
    fetchSuppliers() {
      store.dispatch('apps-products-edit/fetchSuppliers', {
        sortBy: 'name',
        sortDesc: false,
      })
        .then(response => {
          this.suppliersOptions = response.data.items
        })
        .catch(() => {
          this.suppliersOptions = []
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het ophalen van de labels',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    syncProductPrices() {
      store.dispatch('apps-products-edit/syncProductPrices', { id: this.productsData.id })
        .then(response => {
          this.$refs.refreshProduct.showLoading = false
          if (response.data.success) {
            this.fetchProduct()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Er is een error opgetreden bij het updaten van de product prijzen.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(() => {
          this.$refs.refreshProduct.showLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het updaten van het product',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    onSubmit1() {
      this.updateProduct(
        {
          id: this.productsData.id,
          type: this.productsData.type,
          active: this.productsData.active,
          name: this.productsData.name,
          supplierID: this.productsData.supplierID,
          description: this.productsData.description,
          uspText: this.productsData.uspText.replaceAll('p>', 'div>').replace('<div><br></div>', '<br/>'),
        },
      )
    },
    onSubmit2() {
      if (this.productsData.type === 'GAS') {
        this.productsData.ecedoElectricityPropositionSuffix = null
        this.productsData.ecedoElectricityMeeliftPropositionReference = null
      }
      if (this.productsData.type === 'ELECTRICITY') {
        this.productsData.ecedoGasPropositionSuffix = null
        this.productsData.ecedoGasMeeliftPropositionReference = null
      }

      this.updateProduct(
        {
          id: this.productsData.id,
          ecedoElectricityPropositionSuffix: this.productsData.ecedoElectricityPropositionSuffix,
          ecedoGasPropositionSuffix: this.productsData.ecedoGasPropositionSuffix,
          ecedoElectricityMeeliftPropositionReference: this.productsData.ecedoElectricityMeeliftPropositionReference,
          ecedoGasMeeliftPropositionReference: this.productsData.ecedoGasMeeliftPropositionReference,
          contractDuration: this.productsData.contractDuration,
          bagLimitationType: this.productsData.bagLimitationType,
          isBusiness: this.productsData.isBusiness,
          isSohoProposition: this.productsData.isSohoProposition,
          isGV: this.productsData.isGV,
          orderExpiryDays: this.productsData.orderExpiryDays,
          overideEcedoElectricityPVEAN: this.productsData.overideEcedoElectricityPVEAN,
          overideEcedoGasPVEAN: this.productsData.overideEcedoGasPVEAN,
        },
      )
    },
    onSubmit4() {
      this.updateProduct(
        {
          id: this.productsData.id,
          pdfTemplateID: this.productsData.pdfTemplateID,
          electricityType: this.productsData.electricityType,
          gasType: this.productsData.gasType,
          reflectionPeriodText: this.productsData.reflectionPeriodText,
          fineCompensationText: this.productsData.fineCompensationText,
          loyaltyBonusText: this.productsData.loyaltyBonusText,
          cashBackText: this.productsData.cashBackText,
          policyText: this.productsData.policyText || this.productsData.policyText !== '' ? this.productsData.policyText.replaceAll('p>', 'div>').replace('<div><br></div>', '<br/>') : '',
          extraInformationText: this.productsData.extraInformationText || this.productsData.extraInformationText !== '' ? this.productsData.extraInformationText.replaceAll('p>', 'div>').replace('<div><br></div>', '<br/>') : '',
        },
      )
    },
    onSubmit5() {
      this.updateProduct(
        {
          id: this.productsData.id,
          switchWindowMinDays: this.productsData.switchWindowMinDays,
          switchWindowsMaxDays: this.productsData.switchWindowsMaxDays,
          defaultSwitchWindowDays: this.productsData.defaultSwitchWindowDays,
        },
      )
    },
    updateProduct(data) {
      store.dispatch('apps-products-edit/updateProducts', data)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Product geupdated!',
            text: `${errorAndSuccesDefaultMessage.updateDataSucces}`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error bij het updaten van het product',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getFiles(id) {
      store.dispatch('apps-products-edit/getFiles', { id })
        .then(response => {
          this.files = response.data.items
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Documenten',
            text: 'Er is een fout opgetreden bij het ophalen van de documenten. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    createProductFile() {
      this.showCreateProductLoader = true
      store.dispatch('apps-products-edit/createFile', this.newFile)
        .then(response => {
          this.newFile.fileID = response.data.fileID
          this.getFiles(this.productsData.id)
          this.newFile = {
            fileID: null,
            description: null,
            type: null,
            productID: null,
          }
          this.showCreateProductLoader = false
          this.$refs.documentUpload.hide()
        })
        .catch(() => {
          this.showCreateProductLoader = false
          this.newFile.description = null
          this.$swal({
            icon: 'error',
            title: 'Document',
            text: 'Er is een fout opgetreden bij het aanmaken van het document. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    removeFile(id) {
      this.showDeleteDocumentLoader = true
      store.dispatch('apps-products-edit/deleteFile', { id })
        .then(() => {
          this.showDeleteDocumentLoader = false
          this.getFiles(this.productsData.id)
        })
        .catch(() => {
          this.showDeleteDocumentLoader = false
          this.$swal({
            icon: 'error',
            title: 'Document',
            text: 'Er is een fout opgetreden bij het verwijderen van het document. Probeer later het nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    uploadDocument() {
      const file = document.querySelector('#refInputEl1').files[0]
      this.showUploadLoader = true

      if (file.size > 10485760) {
        this.showUploadLoader = false

        this.$swal({
          icon: 'error',
          title: 'Afbeeldingsgrootte',
          text: 'De maximale afbeeldinggrootte is 10mb. Het huidige bestand is te groot.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } else {
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const fileUploadData = {
              file: reader.result,
              relationModel: 'Product',
              name: file.name,
              relationID: this.productsData.id,
            }

            store.dispatch('apps-products-edit/uploadFile', fileUploadData)
              .then(response => {
                this.newFile.description = file.name
                this.newFile.fileID = response.data.fileID
                this.newFile.productID = this.productsData.id
                this.showUploadLoader = false
              })
              .catch(() => {
                this.newFile.description = null
                this.showUploadLoader = false
                this.$swal({
                  icon: 'error',
                  title: 'Uploaden',
                  text: 'Er is een fout opgetreden bij het uploaden van het bestand. Probeer later het nog een keer.',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })
          },
          false,
        )

        reader.readAsDataURL(file)
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'apps-products-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, productsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    const {
      statusOptions,
      productTypeOptions,
      typeOptions,
      fileTypeOptions,
      pdfTempalates,
    } = useProductsList()

    return {
      statusOptions,
      typeOptions,
      avatarText,
      refFormObserver,
      getValidationState,
      productTypeOptions,
      fileTypeOptions,
      pdfTempalates,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
