import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}products`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}products/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}products`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProducts(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}products/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProducts(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${axiosDefaultConfig.backendEndPoint}products/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    duplicateProduct(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}products/${data.id}/duplicate`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductCategories(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}productcategories?fields=id,name`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSuppliers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}suppliers`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    syncProductPrices(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}products/${data.id}/sync`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFiles(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}productfiles?productID=${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}productfiles`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${axiosDefaultConfig.backendEndPoint}productfiles/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}file/upload`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductBillingItems(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}productbillingitems?productID=${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProductBillingItem(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}productbillingitems/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createProductBillingItem(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}productbillingitems`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductBillingItem(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${axiosDefaultConfig.backendEndPoint}productbillingitems/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
