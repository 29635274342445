import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProductsList() {
  // Use toast
  const toast = useToast()

  const refProductsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Naam', key: 'name', sortable: true },
    { label: 'Omschrijving', key: 'description', sortable: true },
    { label: 'Aanmaakdatum', key: 'createTimestamp', sortable: true },
    { label: 'Status', key: 'status', sortable: true },
    { label: 'Acties', key: 'actions', sortable: false },
  ]
  const perPage = ref(10)
  const totalProducts = ref(0)
  const productsCallback = ref([])
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)
  const products = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refProductsListTable.value ? refProductsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProducts.value,
    }
  })

  const refetchProductsData = () => {
    refProductsListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchProductsData()
  })

  const fetchProducts = (ctx, callback) => {
    store
      .dispatch('apps-products-list/fetchProducts', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        products.value = items
        showOverlay.value = false
        totalProducts.value = _meta.totalCount
        productsCallback.value = items
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de productenlijst',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const statusOptions = [
    { label: 'Inactief', value: 0 },
    { label: 'Actief', value: 1 },
  ]

  const typeOptions = [
    { label: 'Stroom & Gas', value: 'DUAL' },
    { label: 'Stroom', value: 'ELECTRICITY' },
    { label: 'Gas', value: 'GAS' },
  ]
  const yesOrNoOptions = [
    { label: 'Nee', value: 0 },
    { label: 'Ja', value: 1 },
  ]

  const fileTypeOptions = [
    { label: 'Productvoorwaarden', value: 'PRODUCTPOLICY' },
    { label: 'Algemene voorwaarden', value: 'TERMSANDCONDITIONS' },
    { label: 'Bonusvoorwaarden', value: 'BONUSPOLICY' },
    { label: 'Compensatie voorwaarden', value: 'COMPENSATIONPOLICY' },
    { label: 'Herroepingsformulier', value: 'REVOCATIONFORM' },
    { label: 'Laadpalen voorwaarden', value: 'CHARGINGPOINTPRODUCTPOLICY' },
    { label: 'Anders', value: 'OTHER' },
  ]

  const pdfTempalates = [
    { label: 'Kleinverbruik', value: 1 },
    { label: 'Grootverbruik', value: 2 },
  ]

  const resolveProductStatusVariant = data => {
    if (data.active === 1 && (!data.currentTariff || data.currentTariff.length <= 0)) return 'warning'
    if (data.active === 1 && data.currentTariff) return 'success'
    if (data.active === 0) return 'warning'
    return 'primary'
  }

  const resolveProductStatusName = data => {
    if (data.active === 1 && (!data.currentTariff || data.currentTariff.length <= 0)) return 'Tarieven verlopen'
    if (data.active === 1 && data.currentTariff) return 'Actief'
    if (data.active === 0) return 'Inactief'
    return ''
  }

  return {
    fetchProducts,
    tableColumns,
    perPage,
    currentPage,
    totalProducts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    refProductsListTable,
    refetchProductsData,
    resolveProductStatusVariant,
    resolveProductStatusName,
    statusOptions,
    typeOptions,
    yesOrNoOptions,
    isSortDirDesc,
    showOverlay,
    fileTypeOptions,
    productsCallback,
    pdfTempalates,
    products,
  }
}
